import {
  PUBLIC_SANITY_DATASET,
  PUBLIC_SANITY_PROJECT_ID,
} from "$env/static/public";
import {
  ClientError,
  SanityClient,
  createClient,
  type QueryParams,
  type SanityDocument,
} from "@sanity/client";
import { error, type HttpError } from "@sveltejs/kit";
import type { Link, SanityImageAsset } from "$generated/sanity";
import { ERROR_CODES } from "../contsts";
import { captureError } from "@hyperfocal/utils/logger";

/**
 * Sanity API client
 */

export const sanity: SanityClient["fetch"] = async <T>(
  query: Parameters<SanityClient["fetch"]>[0],
  params: Parameters<SanityClient["fetch"]>[1],
) => {
  const client = createClient({
    projectId: PUBLIC_SANITY_PROJECT_ID,
    dataset: PUBLIC_SANITY_DATASET,
    apiVersion: "2024-06-01",
    useCdn: false,
  });

  try {
    const result = await client.fetch<T>(query, params as QueryParams);

    if (!result) {
      error(404, ERROR_CODES.NOT_FOUND);
    }

    return result as T;
  } catch (err) {
    if ((err as HttpError).status === 404) {
      return;
    }
    captureError(err as Error, { throw: true });
  }
};

/**
 * Link resolver
 */
export function resolveDocument(
  doc: SanityDocument & { slug?: { current: string } },
) {
  switch (doc?._type) {
    case "home":
      return "/";
    case "legal":
      return `/legal/${doc?.slug?.current}`;
    default:
      return `/${doc?._id}`;
  }
}

export function resolveLink(link?: Link) {
  if (!link) {
    return "";
  }

  const { internalLink, externalLink } = link;
  return internalLink
    ? `${resolveDocument(internalLink.document as unknown as SanityDocument)}${internalLink.queryhash ?? ""}`
    : (externalLink ?? "");
}

/**
 * Image helpers
 */
export function src(url?: string) {
  return `${url}?auto=format&quality=75`;
}

export function srcset(url?: string) {
  const resolutions = [];
  const sets = [];

  let prev = 96;
  while (prev <= 3000) {
    resolutions.push(2 * Math.round(prev / 2));
    prev *= 1 + 0.08 * 2;
  }
  for (let i = 0; i < resolutions.length; i++) {
    sets.push(`${src(url)}&fit=max&w=${resolutions[i]} ${resolutions[i]}w`);
  }

  return sets.join(", ");
}

export function imgProps(
  img?: SanityImageAsset,
  loading: "lazy" | "eager" = "lazy",
) {
  return {
    width: img?.metadata?.dimensions?.width,
    height: img?.metadata?.dimensions?.height,
    alt: img?.altText ?? img?.description ?? img?.title,
    ...(img?.extension === "svg"
      ? {
          src: img.url,
        }
      : {
          src: src(img?.url),
          srcset: srcset(img?.url),
          loading,
          style: img?.metadata?.palette?.muted
            ? `background: ${img?.metadata?.palette?.muted?.background};`
            : undefined,
        }),
  };
}

/**
 * Video helpers
 */
export function mux(id?: string) {
  return id ? `https://stream.mux.com/${id}.m3u8` : "";
}
